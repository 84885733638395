import * as React from "react"
import loadable from '@loadable/component'

import Layout from "../components/layout/layout"
import CapabilitiesSection from "../components/sections/capabilities-section"
import Seo from "../components/seo"
import CtaSection from "../components/sections/cta-section"

const HubspotQuote = loadable(() => import('../components/common/hubspot-quote'))

const QuotePage = () => (
  <Layout>
    <Seo title="Free Quote" />
    <div className="bg-blue-100 py-12">
      <div className="w-11/12 max-w-xl mx-auto">
        <HubspotQuote />
      </div>
    </div>
    <CtaSection />
    <CapabilitiesSection />
  </Layout>
)

export default QuotePage
